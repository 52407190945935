import React from "react";
import Scroller from "../Scroller/Scroller";
import {
  StyledTimeline, 
  StyledTimelineContainer, 
  StyledTimelineItems, 
  StyledTimelineItemContainer,
  StyledTimelineItem,
  StyledTimelineItemLine,
  StyledTimelineItemCircle,
  StyledTimelineDate,
  StyledTimelineTitle,
  StyledTimelineImage,
} from "./style";

const createComponent = (item, index) => {
  return (
    <StyledTimelineItemContainer key={index}>
      <StyledTimelineItem>
        <StyledTimelineDate dangerouslySetInnerHTML={{ __html: item.date }}/>
      </StyledTimelineItem>
      <StyledTimelineItem>
        <StyledTimelineTitle dangerouslySetInnerHTML={{ __html: item.label }}/>
        <StyledTimelineImage src={item.image} loading="lazy" />
      </StyledTimelineItem>
      <StyledTimelineItemLine/>
      <StyledTimelineItemCircle/>
    </StyledTimelineItemContainer>
  );
};

export default ({
  id,
  items,
}) => {
  return (
    <StyledTimeline id={id}>
      <Scroller desktop>
        <StyledTimelineContainer>
          <StyledTimelineItems>
            {items.map(createComponent)}
          </StyledTimelineItems>
        </StyledTimelineContainer>
      </Scroller>
    </StyledTimeline>
  );
};
